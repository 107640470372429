export default {
  defaultWeb3Provider: process.env.REACT_APP_RPC_URL,
  infuraId: process.env.REACT_APP_INFURA_ID,
  blocknative: process.env.REACT_APP_BLOCKNATIVE_KEY,
  cmpAddress: {
    1: '0x9f20ed5f919dc1c1695042542c13adcfc100dcab',
    56: '0x96124f7382a0ed672bba8f9b92208434eabcfb40',
    100: '0x911F196Ed489e41C8B45B5C56FEce021C27a6159',
  },
  veCMPAddress: {
    1: '0x90A460B2DB6FB68A327a5bA928D520495F533fDe',
    56: '',
    100: '',
  },
  veCMPDistributionAddress: {
    1: '0x5774Dbecf0e1eBFD9b4F5D3dc178A00aB506C588',
    56: '',
    100: '',
  },
  pools: {
    1: [
      {
        shell: '0x49519631B404E06ca79C9C7b0dC91648D86F08db',
        name: '50% USDP 25% USDt 25% USDc',
        displayDecimals: 2,
        swapDecimals: 2,
        tag: 'STABLECOIN1',
        hideapy: true,
        assets: [
          {
            address: '0x1456688345527bE1f37E9e627DA0837D6f08C925',
            decimals: 18,
            icon: 'usdp.png',
            name: 'Unit Protocol Stablecoin',
            symbol: 'USDP',
            weight: '.5',
            derivatives: [],
          },
          {
            address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
            decimals: 6,
            icon: 'usdc.svg',
            name: 'USD Coin',
            symbol: 'USDC',
            weight: '.25',
            derivatives: [],
          },
          {
            address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
            decimals: 6,
            icon: 'usdt.svg',
            name: 'Tether Stablecoin',
            symbol: 'USDT',
            weight: '.25',
            approveToZero: true,
            derivatives: [],
          },
        ],
        params: {
          alpha: '.95',
          beta: '.45',
          delta: '.05',
          epsilon: '.0004',
          lambda: '1',
        },
      },

      {
        shell: '0x6477960dd932d29518D7e8087d5Ea3D11E606068',
        name: '50% USDP 25% DAI 25% sUSD',
        displayDecimals: 2,
        swapDecimals: 2,
        tag: 'STABLECOIN2',
        hideapy: true,
        assets: [
          {
            address: '0x1456688345527bE1f37E9e627DA0837D6f08C925',
            decimals: 18,
            icon: 'usdp.png',
            name: 'Unit Protocol Stablecoin',
            symbol: 'USDP',
            weight: '.5',
            derivatives: [],
          },
          {
            address: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
            decimals: 18,
            icon: 'dai.svg',
            name: 'MultiCollateral Dai',
            symbol: 'DAI',
            weight: '.25',
            derivatives: [],
          },
          {
            address: '0x57Ab1ec28D129707052df4dF418D58a2D46d5f51',
            decimals: 18,
            icon: 'susd.svg',
            name: 'Synthetix USD',
            symbol: 'SUSD',
            weight: '.25',
            derivatives: [],
          },
        ],
        params: {
          alpha: '.95',
          beta: '.45',
          delta: '.05',
          epsilon: '.0004',
          lambda: '1',
        },
      },
    ],
    56: [
      {
        shell: '0xcf76a0ceDf50DA184FDef08A9d04E6829D7FefDF',
        name: '50% USDP 25% BUSD 25% USDC',
        displayDecimals: 2,
        swapDecimals: 2,
        tag: 'STABLECOIN1',
        hideapy: true,
        farming: false,
        assets: [
          {
            address: '0xDACD011A71f8c9619642bf482f1D4CeB338cfFCf',
            decimals: 18,
            icon: 'usdp.png',
            name: 'Unit Protocol Stablecoin',
            symbol: 'USDP',
            weight: '.5',
            derivatives: [],
          },
          {
            address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
            decimals: 18,
            icon: 'busd.svg',
            name: 'BUSD',
            symbol: 'BUSD',
            weight: '.25',
            derivatives: [],
          },
          {
            address: '0x55d398326f99059fF775485246999027B3197955',
            decimals: 18,
            icon: 'usdt.svg',
            name: 'USDT',
            symbol: 'USDT',
            weight: '.25',
            derivatives: [],
          },
        ],
        params: {
          alpha: '.95',
          beta: '.45',
          delta: '.05',
          epsilon: '.0004',
          lambda: '1',
        },
      },
      {
        shell: '0x3Bb6Bf6EcBC71f8f78D1Eec9c91de4f8Fd5C891c',
        name: '45% BUSD 45% USDT 10% USDC',
        displayDecimals: 2,
        swapDecimals: 2,
        tag: 'STABLECOIN2',
        hideapy: true,
        farming: false,
        assets: [
          {
            address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
            decimals: 18,
            icon: 'busd.svg',
            name: 'BUSD',
            symbol: 'BUSD',
            weight: '.45',
            derivatives: [],
          },
          {
            address: '0x55d398326f99059fF775485246999027B3197955',
            decimals: 18,
            icon: 'usdt.svg',
            name: 'USDT',
            symbol: 'USDT',
            weight: '.45',
            derivatives: [],
          },
          {
            address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
            decimals: 18,
            icon: 'usdc.svg',
            name: 'USDC',
            symbol: 'USDC',
            weight: '.10',
            derivatives: [],
          },
        ],
        params: {
          alpha: '.95',
          beta: '.45',
          delta: '.05',
          epsilon: '.0004',
          lambda: '1',
        },
      },
    ],
    100: [
      {
        shell: '0x53De001bbfAe8cEcBbD6245817512F8DBd8EEF18',
        name: '50% USDP 25% WXDAI 25% USDc',
        displayDecimals: 2,
        swapDecimals: 2,
        tag: 'STABLECOIN1',
        hideapy: true,
        farming: false,
        assets: [
          {
            address: '0xFe7ed09C4956f7cdb54eC4ffCB9818Db2D7025b8',
            decimals: 18,
            icon: 'usdp.png',
            name: 'Unit Protocol Stablecoin',
            symbol: 'USDP',
            weight: '.5',
            derivatives: [],
          },
          {
            address: '0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d',
            decimals: 18,
            icon: 'dai.svg',
            name: 'Wrapped XDAI',
            symbol: 'WXDAI',
            weight: '.25',
            derivatives: [],
          },
          {
            address: '0xDDAfbb505ad214D7b80b1f830fcCc89B60fb7A83',
            decimals: 6,
            icon: 'usdc.svg',
            name: 'USD Coin',
            symbol: 'USDC',
            weight: '.25',
            derivatives: [],
          },
        ],
        params: {
          alpha: '.95',
          beta: '.45',
          delta: '.05',
          epsilon: '.0004',
          lambda: '1',
        },
      },
      {
        shell: '0xF82fc0ecBf3ff8e253a262447335d3d8A72CD028',
        name: '50% WXDAI 50% DAI',
        displayDecimals: 2,
        swapDecimals: 2,
        tag: 'STABLECOIN2',
        hideapy: true,
        farming: false,
        assets: [
          {
            address: '0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d',
            decimals: 18,
            icon: 'dai.svg',
            name: 'Wrapped XDAI',
            symbol: 'WXDAI',
            weight: '.5',
            derivatives: [],
          },
          {
            address: '0xFc8B2690F66B46fEC8B3ceeb95fF4Ac35a0054BC',
            decimals: 18,
            icon: 'dai.svg',
            name: 'Dai Token on xDai',
            symbol: 'DAI (BSC)',
            weight: '.5',
            derivatives: [],
          },
        ],
        params: {
          alpha: '.95',
          beta: '.45',
          delta: '.05',
          epsilon: '.002',
          lambda: '1',
        },
      },
      {
        shell: '0xfbbd0F67cEbCA3252717E66c1Ed1E97ad8B06377',
        name: '25% DAI(bsc) 25% USDC(bsc) 25% USDC 25% WXDAI',
        displayDecimals: 2,
        swapDecimals: 2,
        tag: 'STABLECOIN3',
        hideapy: true,
        farming: false,
        assets: [
          {
            address: '0xFc8B2690F66B46fEC8B3ceeb95fF4Ac35a0054BC',
            decimals: 18,
            icon: 'dai.svg',
            name: 'Dai Token on xDai',
            symbol: 'DAI (BSC)',
            weight: '.25',
            derivatives: [],
          },
          {
            address: '0xD10Cc63531a514BBa7789682E487Add1f15A51E2',
            decimals: 18,
            icon: 'usdc.svg',
            name: 'USD Coin on xDai',
            symbol: 'USDC (BSC)',
            weight: '.25',
            derivatives: [],
          },
          {
            address: '0xDDAfbb505ad214D7b80b1f830fcCc89B60fb7A83',
            decimals: 6,
            icon: 'usdc.svg',
            name: 'USD Coin',
            symbol: 'USDC',
            weight: '.25',
            derivatives: [],
          },
          {
            address: '0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d',
            decimals: 18,
            icon: 'dai.svg',
            name: 'Wrapped XDAI',
            symbol: 'WXDAI',
            weight: '.25',
            derivatives: [],
          },
        ],
        params: {
          alpha: '.95',
          beta: '.45',
          delta: '.05',
          epsilon: '.002',
          lambda: '1',
        },
      },
      {
        shell: '0x34776e08890bEeCdAc54d20C314246ccea80Cea9',
        name: '22.5% USG 22.5% WXDAI 22.5% USDC 22.5% USDT 10% USDP',
        displayDecimals: 2,
        swapDecimals: 2,
        tag: 'STABLECOIN4',
        hideapy: true,
        farming: false,
        assets: [
          {
            address: '0x068e56eBB63e5f98532bAF94fA1f9b9AE19Ba761',
            decimals: 18,
            icon: 'ic-usg.svg',
            name: 'USG',
            symbol: 'USG',
            weight: '.225',
            derivatives: [],
          },
          {
            address: '0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d',
            decimals: 18,
            icon: 'dai.svg',
            name: 'Wrapped XDAI',
            symbol: 'WXDAI',
            weight: '.225',
            derivatives: [],
          },
          {
            address: '0xDDAfbb505ad214D7b80b1f830fcCc89B60fb7A83',
            decimals: 6,
            icon: 'usdc.svg',
            name: 'USD Coin',
            symbol: 'USDC',
            weight: '.225',
            derivatives: [],
          },
          {
            address: '0x4ecaba5870353805a9f068101a40e0f32ed605c6',
            decimals: 6,
            icon: 'usdt.svg',
            name: 'Tether Stablecoin',
            symbol: 'USDT',
            weight: '.225',
            derivatives: [],
          },
          {
            address: '0xFe7ed09C4956f7cdb54eC4ffCB9818Db2D7025b8',
            decimals: 18,
            icon: 'usdp.png',
            name: 'Unit Protocol Stablecoin',
            symbol: 'USDP',
            weight: '.10',
            derivatives: [],
          },
        ],
        params: {
          alpha: '.95',
          beta: '.45',
          delta: '.05',
          epsilon: '.01',
          lambda: '1',
        },
      },
    ],
    250: [
      {
        shell: '0xddca02ddd94f97eefe07fccde780fd2fbdc85b23',
        name: '50% USDP 25% USDC 25% DAI',
        displayDecimals: 2,
        swapDecimals: 2,
        tag: 'STABLECOIN1',
        hideapy: true,
        farming: false,
        assets: [
          {
            address: '0x3129ac70c738d398d1d74c87eab9483fd56d16f8',
            decimals: 18,
            icon: '/images/fantom/usdp.png',
            name: 'Unit Protocol Stablecoin',
            symbol: 'USDP',
            weight: '.5',
            derivatives: [],
          },
          {
            address: '0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e',
            decimals: 18,
            icon: 'dai.svg',
            name: 'DAI',
            symbol: 'DAI',
            weight: '.25',
            derivatives: [],
          },
          {
            address: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
            decimals: 6,
            icon: 'usdc.svg',
            name: 'USD Coin',
            symbol: 'USDC',
            weight: '.25',
            derivatives: [],
          },
        ],
        params: {
          alpha: '.95',
          beta: '.45',
          delta: '.05',
          epsilon: '.0004',
          lambda: '1',
        },
      },
      {
        shell: '0x238139bf999f389063444e397cdfadf780ec57db',
        name: '50% USDP 25% MIM 25% FRAX',
        displayDecimals: 2,
        swapDecimals: 2,
        tag: 'STABLECOIN2',
        hideapy: true,
        farming: false,
        assets: [
          {
            address: '0x3129ac70c738d398d1d74c87eab9483fd56d16f8',
            decimals: 18,
            icon: '/images/fantom/usdp.png',
            name: 'Unit Protocol Stablecoin',
            symbol: 'USDP',
            weight: '.5',
            derivatives: [],
          },
          {
            address: '0x82f0b8b456c1a451378467398982d4834b6829c1',
            decimals: 18,
            icon: 'dai.svg',
            name: 'MIM',
            symbol: 'MIM',
            weight: '.25',
            derivatives: [],
          },
          {
            address: '0xdc301622e621166bd8e82f2ca0a26c13ad0be355',
            decimals: 18,
            icon: 'frax.png',
            name: 'FRAX',
            symbol: 'FRAX',
            weight: '.25',
            derivatives: [],
          },
        ],
        params: {
          alpha: '.95',
          beta: '.45',
          delta: '.05',
          epsilon: '.0004',
          lambda: '1',
        },
      },
    ],
  },
  farmingPools: {
    1: [
      {
        managerAddress: '0x7351A6D36FdE5c7D5D2a23eE0a26feB8083F91B5',
        underlyingPoolAddress: '0x49519631b404e06ca79c9c7b0dc91648d86f08db',
        name: 'USDP/USDC/USDT',
        icon: 'logo.png',
        displayDecimals: 4,
        decimals: 18,
        periodDuration: 2592000,
      },
      {
        managerAddress: '0x2BE4d20b9eeB7d625FA68112568F94c615Af376B',
        underlyingPoolAddress: '0x6477960dd932d29518D7e8087d5Ea3D11E606068',
        name: 'USDP/DAI/SUSD',
        icon: 'logo.png',
        displayDecimals: 4,
        decimals: 18,
        periodDuration: 2592000,
      },
      {
        managerAddress: '0x5D4066ed92F0FE565747FB75C128995e0b757d7d',
        underlyingPoolAddress: '0x6f21eea19c917fd5ce48cabfeb3bd723dfa508a6',
        name: 'CMP/ETH',
        icon: 'logo.png',
        displayDecimals: 4,
        decimals: 18,
        periodDuration: 2592000,
      },
    ],
    56: [
      {
        managerAddress: '0xEAc13bda20A0A81f5Cb0ADdC4a091d00344C2E1b',
        underlyingPoolAddress: '0xcf76a0ceDf50DA184FDef08A9d04E6829D7FefDF',
        name: 'USDP/BUSD/USDT',
        icon: 'logo.png',
        displayDecimals: 4,
        decimals: 18,
        periodDuration: 2592000,
      },
      {
        managerAddress: '0xB56689C8746A14e8ba1d8050e55eF241268178eB',
        underlyingPoolAddress: '0x3Bb6Bf6EcBC71f8f78D1Eec9c91de4f8Fd5C891c',
        name: 'BUSD/USDT/USDC',
        icon: 'logo.png',
        displayDecimals: 4,
        decimals: 18,
        periodDuration: 2592000,
      },
      {
        managerAddress: '0x53De001bbfAe8cEcBbD6245817512F8DBd8EEF18',
        underlyingPoolAddress: '0x696741234737f93FA45017E4891DEa87070896d8',
        name: 'CMP/BNB',
        icon: 'logo.png',
        displayDecimals: 4,
        decimals: 18,
        periodDuration: 2592000,
      },
    ],
    100: [
      {
        managerAddress: '0x3E404531f7bFee17dfCb614204Bac4de10C5864b',
        underlyingPoolAddress: '0x34776e08890beecdac54d20c314246ccea80cea9',
        name: 'USG/WXDAI/USDC/USDT/USDP',
        icon: 'logo.png',
        displayDecimals: 4,
        decimals: 18,
        periodDuration: 2592000,
      },
      {
        managerAddress: '0x79876b5062160C107e02826371dD33c047CCF2de',
        underlyingPoolAddress: '0x53De001bbfAe8cEcBbD6245817512F8DBd8EEF18',
        name: 'USDP/WXDAI/USDC',
        icon: 'logo.png',
        displayDecimals: 4,
        decimals: 18,
        periodDuration: 2592000,
      },
      {
        managerAddress: '0xdbd9aC0802544F6D293B293ee15E82d3Ec410CCb',
        underlyingPoolAddress: '0xF82fc0ecBf3ff8e253a262447335d3d8A72CD028',
        name: 'WXDAI/DAI(BSC)',
        icon: 'logo.png',
        displayDecimals: 4,
        decimals: 18,
        periodDuration: 2592000,
      },
      {
        managerAddress: '0xF3aC78AF33B79E3106e1d31c769cd1D54C487E69',
        underlyingPoolAddress: '0xfbbd0F67cEbCA3252717E66c1Ed1E97ad8B06377',
        name: 'DAI(BSC)/USDC(BSC)/USDC/WXDAI',
        icon: 'logo.png',
        displayDecimals: 4,
        decimals: 18,
        periodDuration: 2592000,
      },
      {
        managerAddress: '0xc2004547637240d4d8DC376B0fe8C08C563224F9',
        underlyingPoolAddress: '0xd51Ecf96357aB928227Ef25179d12731C986Ca0b',
        name: 'CMP/XDAI',
        icon: 'logo.png',
        displayDecimals: 4,
        decimals: 18,
        periodDuration: 2592000,
      },
    ],
    250: [],
  },
  stakingPools: {
    1: [
      {
        managerAddress: '0x79876b5062160C107e02826371dD33c047CCF2de',
        underlyingPoolAddress: '0x9f20ed5f919dc1c1695042542c13adcfc100dcab',
        name: 'CMP',
        icon: 'logo.png',
        displayDecimals: 4,
        decimals: 18,
        periodDuration: 2592000,
        deprecated: true,
      },
    ],
    56: [],
    100: [],
    250: [],
  },
  lockingPools: {
    1: [],
    56: [],
    100: [],
    250: [],
  }
};
